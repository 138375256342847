import { useEffect } from "react";
import { useDataStore } from "./DataStoreProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Loading from './Loading';
import HeartwoodLib from "./HeartwoodLib";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_7knsBJ4WTrNaDt2M8RTXKvRS");
// const stripePromise = loadStripe("pk_test_yLpfQAYadteE3vgsm0gj5EQj");

function Payment() {

  const { appData, setAppData } = useDataStore(); 

  useEffect(() => {
      // Create PaymentIntent as soon as the page loads
      fetch(appData.endpoint + "payment_intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer_id : appData.customer_id,
          total : HeartwoodLib.calcTotal(appData) 
        }),
      })
      .then((res) => res.json())
      .then((data) => {
        setAppData({
          ...appData,
          clientSecret : data.clientSecret,
        });
      });
  }, []);
  
  if (appData.clientSecret) {
    return (
      <div className="innerContent">
        <Elements options={{clientSecret : appData.clientSecret}} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    );
  
  }
  else {
    return (
      <div className="innerContent">
        <Loading />    
      </div>
    );
  }
}

export default Payment;