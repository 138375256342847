import { useDataStore } from "./DataStoreProvider";
import HeartwoodLib from "./HeartwoodLib";

function Lodging() {
  const { appData, setAppData } = useDataStore(); 
  
  const campingOK = (e) => {
    e.preventDefault();
    
    setAppData({
      ...appData,
      needbed : "camping",
    })
  }
  
  const needBed = (e) => {
    e.preventDefault();
    
    setAppData({
      ...appData,
      needbed: "bunk",
    });
  }

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value.replace(/^\s+|^\s+$/g, "");   
    
    if (["1", "2", "3", "4", "5", "6", "7", "8", "9" , "0", "10"].includes(val)) {
      setAppData({ ...appData, [key]: parseInt(val) });
    }
    else if (val.match(/^[.\d]+$/)) {
      setAppData({ ...appData, [key]: parseFloat(val) });
    }
    else {
      setAppData({ ...appData, [key]: val });   
    }
  };  
  
  const formActionCheck = (event) => {
    const key = event.target.getAttribute('name');
    if (appData[key]) {
      setAppData({ ...appData, [key] : false });
    }
    else {
      setAppData({ ...appData, [key] : true });
    }
  };

  if ("bunk" === appData.needbed) {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>&nbsp;</th><th>Number of beds (adults <em>or</em> kids, $20/full event or $10/night)</th><th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Full Weekend (Fri, Sat, Sun nights)</td>
            <td>
              <input type="number" min="0" step="1" name="fullCabin" value={appData.fullCabin} onChange={formAction} className="form-control" />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Partial Weekend</td>
            <td>
              <input type="number" min="0" step="1" name="partialCabin" value={appData.partialCabin} onChange={formAction} className="form-control" />
            </td>
            <td>
              <input type="checkbox" name="friCabin" value={true} checked={appData.friCabin} onChange={formActionCheck} /> Friday &nbsp; 
              <input type="checkbox" name="satCabin" value={true} checked={appData.satCabin} onChange={formActionCheck} /> Saturday &nbsp; 
              <input type="checkbox" name="sunCabin" value={true} checked={appData.sunCabin} onChange={formActionCheck} /> Sunday
            </td>
          </tr>
        </tbody>
      </table>    
    );  
  }
  
  else if ("camping" == appData.needbed) {
    return (
      <div className="row" style={{"marginBottom":"15px"}}>
        <div className="col-10 offset-1">
          <strong>Remember to Bring With</strong>
          <ul>
            <li>(if camping) Tent and sleeping bags</li>
            <li>water bottle(s)</li>
            <li>rain gear</li>
            <li>evening chill gear</li>
          </ul>
        </div>
      </div>    
    );
  }
  
  else {
    return (
      <div className="row" style={{"marginBottom":"15px","textAlign":"center"}}>
        <div className="col-5 offset-1">
          <button className="btn btn-lg btn-success" onClick={campingOK} style={{"margin":10}}>
            I am able to camp or secure a local hotel room. 
          </button>
        </div>
        <div className="col-5">
          <button className="btn btn-lg btn-secondary" onClick={needBed} style={{"margin":10}}>
            I really need an onsite bunk bed.
          </button>
        </div>
      </div>
    );
  }
}


function RegForm() {
  const { appData, setAppData } = useDataStore(); 
  
  const total = HeartwoodLib.calcTotal(appData);
 
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value.replace(/^\s+|^\s+$/g, "");   
    
    if (["1", "2", "3", "4", "5", "6", "7", "8", "9" , "0", "10"].includes(val)) {
      setAppData({ ...appData, [key]: parseInt(val) });
    }
    else if (val.match(/^[.\d]+$/)) {
      setAppData({ ...appData, [key]: parseFloat(val) });
    }
    else {
      setAppData({ ...appData, [key]: val });   
    }
  };  
  
  const formActionCheck = (event) => {
    const key = event.target.getAttribute('name');
    if (appData[key]) {
      setAppData({ ...appData, [key] : false });
    }
    else {
      setAppData({ ...appData, [key] : true });
    }
  };
  
  const formCheck = (paymentPage) => {
    const people = (appData.adultFull ? parseInt(appData.adultFull) : 0 )
                 + (appData.adultPartial ? parseInt(appData.adultPartial) : 0);
                                 
    if ("" === appData.registrant) {
      setAppData({
        ...appData,
        registrantClass : "form-control is-invalid",
        adultClass : "form-control",
        contactClass : "form-control",
        warning : "Please list a primary contact person for your registration. (top of form)",
      });
      return false;
    }
  
    if (0 === people) {
      setAppData({
        ...appData,
        adultClass : "form-control is-invalid",
        contactClass : "form-control",
        registrantClass : "form-control",
        warning : "Your registration should include at least one adult.",
      });
      return false;
    }

    const singleMeals = (appData.friDin ? 1 : 0)
                      + (appData.satBre ? 1 : 0)
                      + (appData.satLun ? 1 : 0)
                      + (appData.satDin ? 1 : 0)
                      + (appData.sunBre ? 1 : 0)
                      + (appData.sunLun ? 1 : 0)
                      + (appData.sunDin ? 1 : 0)
                      + (appData.monBre ? 1 : 0);
                      
    
    if ((appData.adultPartial || appData.childPartial) && !singleMeals) {
      setAppData({
        ...appData,
        registrantClass : "form-control",
        adultClass : "form-control",
        contactClass : "form-control",
        warning : "Please indicate which meals you'll be sharing with us.",
      });
      return false;
    }

    else {
      setAppData({
        ...appData,
        page : "loading",
      });

      fetch(appData.endpoint + 'registration', {
        method  : "POST",
        body : JSON.stringify({
          registrant : appData.registrant,
          address : appData.address,
          city : appData.city,
          state : appData.state,
          zip : appData.zip,
          email : appData.email,
          phone : appData.phone,
          adultFull : (appData.adultFull || 0),
          childFull : (appData.childFull || 0),
          adultPartial : (appData.adultPartial || 0),
          childPartial : (appData.childPartial || 0),
          
          membership : (appData.membership || 0.00),
          scholarship : (appData.scholarship | 0.00),
          payWhen : ('payment' === paymentPage ? 'online' : 'at gate'),    

          other : appData.other,
          
          friDinAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.friDin ? 1 : 0))),
          friDinChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.friDin ? 1 : 0))),
          
          satBreAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0 ) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.satBre ? 1 : 0))),
          satBreChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.satBre ? 1 : 0))),
          
          satLunAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0 ) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.satLun ? 1 : 0))),
          satLunChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.satLun ? 1 : 0))),
          
          satDinAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.satDin ? 1 : 0))),
          satDinChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.satDin ? 1 : 0))),
          
          sunBreAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.sunBre ? 1 : 0))),
          sunBreChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.sunBre ? 1 : 0))),
          
          sunLunAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.sunLun ? 1 : 0))),
          sunLunChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.sunLun ? 1 : 0))),
          
          sunDinAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.sunDin ? 1 : 0))),
          sunDinChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.sunDin ? 1 : 0))),
          
          monBreAdult  : ((appData.adultFull ? parseInt(appData.adultFull) : 0) + ((appData.adultPartial ? parseInt(appData.adultPartial) : 0) * (appData.monBre ? 1 : 0))),
          monBreChild  : ((appData.childFull ? parseInt(appData.childFull) : 0) + ((appData.childPartial ? parseInt(appData.childPartial) : 0) * (appData.monBre ? 1 : 0)))
        })
      })
      .then((res) => {
        res.json().then((data) => {
          if ("print" === paymentPage) {
            setAppData({
              ...appData,
              id : data.id,
              page : "print",
            });          
          }
          else {
            setAppData({
              ...appData,
              id   : data.id,
              page : "loading",
            });
            
            fetch(appData.endpoint + 'registration', {
              method  : "PUT",
              body : JSON.stringify({
                id : data.id,
              })
            })
            .then((res) => res.json())
            .then((data) => {
              setAppData({
                ...appData,
                customer_id : data.customer_id,
                id          : data.id,
                page        : "payment",
              });             
            })
            .catch((err) => console.error(err));          
          }
        })
      })
      .catch((err) => console.error(err));
    }

    return true;
  };
  
  const checkoutPayNow = () => {
    if (!appData.email.match(/\w@\w/)) {
      setAppData({
        ...appData,
        registrantClass : "form-control",
        adultClass : "form-control",
        contactClass : "form-control is-invalid",
        warning : "Please provide an email for payment receipts and so we can get in touch if needed. (top of form)",     
      });
      
      return false;
    } 
    
    formCheck('payment');
  };
  
  const checkoutPayLater = () => {
    formCheck('print');
  };
      

  return (
    <div className="innerContent">
      
      <div className="row">
        <div className="col">
          <h1 className="text-center">Heartwood Forest Council Registration Form</h1>
          <h2 className="text-center">Registration strongly encouraged to be received by May 15, 2024.</h2>
          <p className="text-center"><strong>We cannot guarantee food availability if reservations are not made by May 15, 2024.</strong></p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 offset-1">
          <p>
          <strong><em>Full Weekend Meal Prices</em></strong> &mdash; Best deal, includes all activity fees
          </p>

          <p>
          $100 per adult, Children 12 and under free registration.<br />
          Free camping. $20/person for a cabin bunk. 
          </p>

          <p>
          <strong><em>Partial Weekend Meal Prices</em></strong> &mdash; 
          $10 per meal plus $20 per day. 
          </p>

          <p>
          People with special dietary needs will indicate needs on the form.
          </p>       
        </div>
      </div>
      
      
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2 text-md-end">
          Name:
        </div>
        <div className="col-md-10">
          <input type="text" className={appData.registrantClass} name="registrant" value={appData.registrant} onChange={formAction} />
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          Address:
        </div>
        <div className="col-md-10">
          <input type="text" className="form-control" name="address" value={appData.address} onChange={formAction} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          City:
        </div>
        <div className="col-md-10">
          <input type="text" className="form-control" name="city" value={appData.city} onChange={formAction} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          State:
        </div>
        <div className="col-md-10">
          <input type="text" className="form-control" name="state" value={appData.state} onChange={formAction} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          Zip Code:
        </div>
        <div className="col-md-10">
          <input type="text" className="form-control" name="zip" value={appData.zip} onChange={formAction} />
        </div>
      </div>          
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          Email:
        </div>
        <div className="col-md-10">
          <input type="text" className={appData.contactClass} name="email" value={appData.email} onChange={formAction} />
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2 text-md-end">
          Phone:
        </div>
        <div className="col-md-10">
          <input type="text" className="form-control" name="phone" value={appData.phone} onChange={formAction} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"25px"}}>
        <div className="col-10 offset-1">
          <h2 style={{color:"green"}}>MEALS AND ACTIVITIES</h2>
          <strong>How many people are registering? Please fill in the blanks in the table below with the 
          number of people in each category.</strong>
        </div>
      </div>
      
      <table className="table">
        <thead>
        <tr>
          <th>&nbsp;</th><th>12 and older</th><th>under 12 year olds</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Full Weekend<br />
          (4 days, 3 nights, all meals, workshops, outings)</td>
          <td><input type="number" min="0" step="1" name="adultFull" className={appData.adultClass} value={appData.adultFull} onChange={formAction} /></td>
          <td><input type="number" min="0" step="1" name="childFull" className="form-control" value={appData.childFull} onChange={formAction} /></td>
        </tr>
        <tr>
          <td>Partial Weekend<br /></td>
          <td><input type="number" min="0" step="1" name="adultPartial" className={appData.adultClass} value={appData.adultPartial} onChange={formAction} /></td>
          <td><input type="number" min="0" step="1" name="childPartial" className="form-control" value={appData.childPartial} onChange={formAction} /></td>
        </tr>
        <tr style={{"display":(appData.adultPartial || appData.childPartial || appData.infantPartial) ? 'table-row' : 'none'}}>
          <td>Which meals will you share with us?</td>
          <td colSpan="3">
            <input type="checkbox" name="friDin" value={true} checked={appData.friDin} onChange={formActionCheck} /> Friday Dinner&nbsp;<br />
            <input type="checkbox" name="satBre" value={true} checked={appData.satBre} onChange={formActionCheck} /> Saturday Breakfast &nbsp; &nbsp;
            <input type="checkbox" name="satLun" value={true} checked={appData.satLun} onChange={formActionCheck} /> Saturday Lunch &nbsp; &nbsp;
            <input type="checkbox" name="satDin" value={true} checked={appData.satDin} onChange={formActionCheck} /> Saturday Dinner&nbsp;<br  />
            <input type="checkbox" name="sunBre" value={true} checked={appData.sunBre} onChange={formActionCheck} /> Sunday Breakfast &nbsp; &nbsp;
            <input type="checkbox" name="sunLun" value={true} checked={appData.sunLun} onChange={formActionCheck} /> Sunday Lunch &nbsp; &nbsp;
            <input type="checkbox" name="sunDin" value={true} checked={appData.sunDin} onChange={formActionCheck} /> Sunday Dinner&nbsp;<br />
            <input type="checkbox" name="monBre" value={true} checked={appData.monBre} onChange={formActionCheck} /> Monday Breakfast&nbsp;
          </td>
        </tr>
        </tbody>
      </table>
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"25px"}}>
        <div className="col-10 offset-1">
          <h2 style={{color:"green"}}>LODGING</h2>
        </div>
      </div>
      
      <Lodging />
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"25px"}}>
        <div className="col-10 offset-1">
          <h2 style={{color:"green"}}>MEMBERSHIP AND DONATIONS (OPTIONAL)</h2>
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"30px"}}>
        <div className="col-md-3 text-md-end">
          Membership Renewal:
        </div>
        <div className="col-md-9">
          <select name="membership" className="form-control" value={appData.membership} onChange={formAction}>
            <option value="0">(choose amount)</option>
            <option value="30">$30</option>
            <option value="60">$60</option>
            <option value="90">$90</option>
          </select>
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-3 text-md-end">
          Donate to Scholarship Fund:
        </div>
        <div className="col-md-9">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">$</span>
            <input type="number" value={appData.scholarship} onChange={formAction} min="0" name="scholarship" className="form-control" />
          </div>        
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-3 text-md-end">
          <strong>Total:</strong>
        </div>
        <div className="col-md-9">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">$</span>
            <input type="text" name="total" value={total} className="form-control" readOnly />
          </div>        
       </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"25px"}}>
        <div className="col-10 offset-1">
          <h2 style={{color:"green"}}>VOLUNTEER/COST REDUCTION OPPORTUNITIES</h2>
        </div>
      </div>

      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-10 offset-md-1">
          No one will be turned away for inability to pay.<br />
          We will need volunteers to help with many aspects of this event. Volunteers can 
          earn credit against the registration fee at a rate of $15/hour, helping in the 
          kitchen or with other aspects to make the event run smoothly. Contact us to make
          arrangements at <a href="mailto:info@heartwood.org">info@heartwood.org</a>, or 
        </div>
      </div>
            
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-3 text-md-end">
          Other info for us: (dietary requirements, medical needs, travel quirks, etc.)
        </div>
        <div className="col-md-9">
          <div className="input-group mb-3">
            <textarea name="other" value={appData.other} className="form-control" onChange={formAction}></textarea>
          </div>        
       </div>
      </div>
      
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"30px"}}>
        <div className="col-md-3 offset-md-1" style={{"marginBottom":"10px"}}>
          <button className="btn btn-lg btn-success" onClick={checkoutPayNow}>Register &amp; Pay Online Now</button>
        </div>
        <div className="col-md-3"  style={{"marginBottom":"10px"}}>
          <button className="btn btn-lg btn-success" onClick={checkoutPayLater}>Register &amp; Pay at Event</button>
        </div>
      </div>
      
    
    </div>
  );
}

export default RegForm;