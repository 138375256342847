import React, { createContext, useState, useContext } from "react";

const DataStore = createContext();
export const useDataStore = () => useContext(DataStore);

export default function DataStoreProvider({ children }) {

  /* change endpoint here and only hear to select version of back-end code to run against */
  const [appData, setAppData] = useState({ 
    page : "regForm",
    endpoint : 'https://aoufternk0.execute-api.us-east-1.amazonaws.com/0/',
    total : 0,
    registrant : '',
    registrantClass : "form-control",
    address : '',
    city : '',
    state : '',
    zip : '',
    email : '',
    phone : '',
    contactClass : "form-control",
    adultFull : 0,
    childFull : 0,
    infantFull : 0,
    adultPartial : 0,
    childPartial : 0,
    
    friDinAdult : 0,
    friDinChild : 0, 
    
    satBreAdult : 0,
    satBreChild : 0,
    
    satLunAdult  : 0,
    satLunChild  : 0,

    satDinAdult  : 0,
    satDinChild  : 0,

    sunBreAdult  : 0,
    sunBreChild  : 0,

    sunLunAdult  : 0,
    sunLunChild  : 0,

    sunDinAdult  : 0,
    sunDinChild  : 0,

    monBreAdult  : 0,
    monBreChild  : 0,

    adultVegan : 0,
    childVegan : 0,
    
    membership : 0,
    scholarship : 0,
    warning : "",
    adultClass : "form-control",
    payWhen : "",
    message : "",
    isLoading : false,
    clientSecret : "",
    stripeClient : "",

    other : "",
    
    friday : false,
    saturday : false,
    sunday : false,
    monday : false,
    
    needbed : "",
   });

  return (
    <DataStore.Provider value={{ appData, setAppData }}>
      {children}
    </DataStore.Provider>
  );
}