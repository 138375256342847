import { useDataStore } from "./DataStoreProvider";

function Thanks(data) {
  const { appData } = useDataStore(); 
  
  fetch(appData.endpoint + "payment_intent", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
  .catch((err) => console.error(err));
  
  return (
    <div className="innerContent">
      <h1>Thanks!</h1>
      <h2>Confirmation ID: {data.id}</h2>
      <p>Your registration was successful. Please print this page. We look forward to seeing you!</p>
      <p>&nbsp;</p>
      <h3>Remember to Bring With:</h3>
      <ul>
        <li>(if camping) Tent and sleeping bags</li>
        <li>(if staying in cabin) Bedding, blankets, and pillows</li>
        <li>water bottle(s)</li>
        <li>rain gear</li>
        <li>evening chill gear</li>
      </ul>
    </div>
  );
}

export default Thanks;