import { useEffect } from "react";
import { useDataStore } from "./DataStoreProvider";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import HeartwoodLib from "./HeartwoodLib";

function CheckoutForm() {
  const { appData, setAppData } = useDataStore(); 
  const stripe                  = useStripe();
  const elements                = useElements();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setAppData({
            ...appData,
            message : "Payment succeeded!",
          });
          break;
        case "processing":
          setAppData({
            ...appData,
            message : "Your payment is processing.",
          });
          break;
        case "requires_payment_method":
          setAppData({
            ...appData,
            message : "Your payment was not successful, please try again.",
          });
          break;
        default:
          setAppData({
            ...appData,
            message : "Something went wrong.",
          });
          break;
      }
    });
  }, [stripe, appData, setAppData]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setAppData({
      ...appData,
      isLoading : true,
    });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://registration.heartwood.org/?id=" + appData.id,
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      window.document.getElementById('payment-message').style.color = "red";
      window.document.getElementById('payment-message').style.fontWeight = "bold";
      window.document.getElementById('payment-message').innerHTML = error.message;
//       setAppData({
//         ...appData,
//         message : error.message,
//       });
    } 
    else {
      window.document.getElementById('payment-message').style.color = "red";
      window.document.getElementById('payment-message').style.fontWeight = "bold";
      window.document.getElementById('payment-message').innerHTML = "An unexpected error occured.";
//       setAppData({
//         ...appData,
//         message : "An unexpected error occured.",
//       });
    }

    setAppData({
      ...appData,
      isLoading : false,
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <h3>$ {HeartwoodLib.calcTotal(appData)}</h3>
      <PaymentElement id="payment-element" />
      <button className="btn btn-success" 
      style={{"marginTop":"20px"}} 
      disabled={appData.isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {appData.isLoading ? <img style={{"height":"40px","width":"auto"}} src="/load.gif" alt="information is processing..." /> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      <div id="payment-message">{appData.message}</div>
    </form>
  );
  

}

export default CheckoutForm;