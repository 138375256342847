import { useDataStore } from "./DataStoreProvider";
import HeartwoodLib from "./HeartwoodLib";

function Print() {
  const { appData } = useDataStore(); 

  const fullWeekend = () => {
    let out = "";
    if (1 === parseInt(appData.adultFull)) {
      out += '1 adult';
    }
    else if (parseInt(appData.adultFull) > 1) {
      out += String(appData.adultFull) + ' adults';      
    }
    
    if (appData.childFull && out) { out += ', '; }
    
    if (1 === parseInt(appData.childFull)) {
      out += '1 child (< 12)';
    }
    else if (parseInt(appData.childFull) > 1) {
      out += String(appData.childFull) + ' children (< 12)';
    }
        
    return out;
  };
  
  const partialWeekend = () => {
    let out = "";
    if (1 === parseInt(appData.adultPartial)) {
      out += '1 adult';
    }
    else if (parseInt(appData.adultPartial) > 1) {
      out += String(appData.adultPartial) + ' adults';      
    }
    
    if (appData.childPartial && out) { out += ', '; }
    
    if (1 === parseInt(appData.childPartial)) {
      out += '1 child (< 12)';
    }
    else if (parseInt(appData.childPartial) > 1) {
      out += String(appData.childPartial) + ' children (< 12)';
    }
        
    return out;
  };
  
  
  return (
    <div className="innerContent">
      <div className="row">
        <div className="col">
          <h1 className="text-center">
            Print Registration Information (ID: {appData.id})
          </h1>
          <h2 className="text-center">
            Total Due: $ {HeartwoodLib.calcTotal(appData)}
          </h2>
          <p>
            Keep one copy for yourself and mail one copy with your payment to:
          </p>
          <p>
            <strong>Heartwood</strong><br />
            PO Box 352<br />
            Paoli, IN 47454            
          </p>
        </div>
      </div>
      
      <div className="row" style={{"display":((appData.adultFull || appData.childFull) ? "block" : "none")}}>
        <div className="col-md-3">
          <strong>Full Weekend: </strong>
        </div>
        <div className="col-md-9">
          {fullWeekend()}
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"25px","display":((appData.adultBunk || appData.childBunk) ? "block" : "none")}}>
        <div className="col-md-3">
          <strong>Partial Weekend: </strong>
        </div>
        <div className="col-md-9">
          {partialWeekend()}
        </div>
      </div>      
            
      <div className="row" style={{"marginTop":"25px","display":((appData.membership > 0) ? "block" : "none")}}>
        <div className="col-md-3">
          <strong>Membership/Membership Renewal</strong>
        </div>
        <div className="col-md-9">
          $ {parseFloat(appData.membership).toFixed(2)}
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"25px","display":((appData.scholarship > 0) ? "block" : "none")}}>
        <div className="col-md-3">
          <strong>Scholarship Donation</strong>
        </div>
        <div className="col-md-9">
          $ {parseFloat(appData.scholarship).toFixed(2)}
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"25px","display":(appData.other ? "block" : "none")}}>
        <div className="col-md-3">
          <strong>Other</strong>
        </div>
        <div className="col-md-9">
          {appData.other}
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"25px"}}>
        <div className="col">
          <h3>Remember to Bring With</h3>
          <ul>
            <li>(if camping) Tent and sleeping bags</li>
            <li>(if staying in cabin) Bedding, blankets, and pillows</li>
            <li>water bottle(s)</li>
            <li>rain gear</li>
            <li>evening chill gear</li>
          </ul>
        </div>
      </div>
      
    </div>
  );
}

export default Print;