import './App.css';
import DataStoreProvider, { useDataStore } from "./DataStoreProvider";
import RegForm from './RegForm';
import Loading from './Loading';
import Print from './Print';
import Payment from './Payment';
import Thanks from './Thanks';

function Routing() {
  const { appData } = useDataStore(); 
  
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (params.id) {
    const p = {id : params.id, pi : params.payment_intent};
    return <Thanks {...p} />;
  }
  else {
    switch (appData.page) {
    
      case 'loading':
        return <Loading />;
      case 'print':
        return <Print />;
      case 'payment':
        return <Payment />;
    
      default:
        return <RegForm />;
    }  
  }
}


function App() {

  return (
    <DataStoreProvider>
      <header>
        <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample10" aria-controls="navbarsExample10" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-md-center" id="navbarCollapse">
              <ul className="navbar-nav">                
                <li>
                  <a className="nav-link" href="https://heartwood.org/about/" style={{"color":"#63403a"}}>About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://heartwood.org/campaigns/" style={{"color":"#63403a"}}>Our Work</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://heartwood.org/blog/" style={{"color":"#63403a"}}>News</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="https://heartwood.org/events/" style={{"color":"#63403a"}}>Events</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://heartwood.org/heartbeat/" style={{"color":"#63403a"}}>Heartbeat</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://heartwood.org/support/donate/" style={{"color":"#63403a"}}>Support!</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://heartwood.org/contact/" style={{"color":"#63403a"}}>Contact</a>
                </li>
              </ul>  
            </div>
          </div>
        </nav>
      </header>
      <main className="flex-shrink-0">
        <div className="container">
          <div className="container" style={{"height":"100%"}} id="inner-container">
            <Routing />      
          </div>   
        </div>
      </main>
    </DataStoreProvider>
  );
}

export default App;
